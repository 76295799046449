<template>
  <div class="analysis">
    <van-form @submit="onSubmit" style="margin-bottom:52px;">
      <van-cell-group class="mt10">
        <van-cell title="报检编号" value="SC20220820020" />
        <van-cell title="产品名称" value="丙草胺" />
        <van-cell title="产品规格型号" value="100ML*50" />
        <van-cell title="检样员姓名" value="沈家志" />
      </van-cell-group>
      <van-cell-group class="mt10">
        <van-field
          v-model="equipment"
          name="检样设备"
          label="检样设备"
          placeholder="请选择检样设备"
          readonly
          @click="showEquipment"
        />
      </van-cell-group>
      <div class="title">检测项目</div>
      <van-cell-group class="mt10">
        <van-cell title="报检项目" title-style="width:6.2em; flex:none">
          <van-checkbox-group v-model="project" direction="horizontal">
            <van-checkbox icon-size="13px" shape="square" v-for="(itme, idx) in projects" :key="idx" :name="itme.id">{{itme.title}}</van-checkbox>
          </van-checkbox-group>
        </van-cell>
        <van-field
          v-model="message"
          rows="1"
          autosize
          label="说明"
          type="textarea"
          placeholder="请输入其他重要检测说明"
        />
      </van-cell-group>
      <van-cell-group class="mt10">
        <van-button round block size="small" type="primary" native-type="submit">提交</van-button>
      </van-cell-group>
    </van-form>
    <van-popup v-model="equipmentStatus" position="bottom" round :style="{ height: '90%' }">
      <van-index-bar>
        <van-cell v-for="(item, idx) in equipments" :key="idx" :title="item.title" @click="toEquipment(idx)"/>
      </van-index-bar>
    </van-popup>
  </div>
</template>
<script>
import Vue from 'vue';
import { Form, Field, CellGroup, Picker, Popup, IndexBar, IndexAnchor, Checkbox, CheckboxGroup, Uploader, Button } from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Uploader);
Vue.use(Button);
export default {
  data() {
    return {
      equipmentStatus: false,
      productStatus: false,
      type: '',
      no: '',
      time: new Date().toLocaleString(),
      user: '',
      department: '',
      contractNo: '',
      supplier: '',
      product: '',
      inspectionLot: '',
      project: [],
      equipments: [{
        id: '15456',
        title: '山东侨城农化有限公司'
      }, {
        id: '15456',
        title: '首建农业集团有限公司'
      }, {
        id: '15456',
        title: '会议通有限公司'
      }, {
        id: '15456',
        title: '鲁冰齐欧农有限公司'
        }],
      projects:  [{
        id: '1',
        title: '外观'
      }, {
        id: '2',
        title: 'PH值'
      }, {
        id: '3',
        title: '密度'
      }, {
        id: '4',
        title: '稳定性'
        }],
    };
  },
  methods: {
    onSubmit(values) {
      // console.log('submit', values);
    },
    showType() {
      // console.log('showtype')
      this.typeStatus = true
    },
    showEquipment() {
      this.equipmentStatus = true
    },
    showProduct() {
      this.productStatus = true
    },
    toSupplier(idx) {
      this.supplier = this.suppliers[idx].title
      this.supplierStatus = false
    },
    afterRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';

      setTimeout(() => {
        file.status = 'failed';
        file.message = '上传失败';
      }, 1000);
    },
    typeConfirm(value) {      
      this.type = value.text
      this.typeStatus = false
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    typeCancel() {
      this.typeStatus = false
      // Toast('取消');
    },
  },
};
</script>
